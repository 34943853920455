<script setup>
import {useRouter} from "vue-router";
import {onMounted, reactive} from "vue";
import {queryDevice} from "../../api/device";
import {CreateCustomer, queryCustomer, queryCustomerDepartment} from "../../api/customer";
import {Notify, Toast} from "vant";
import dd from "gdt-jsapi";
const router = useRouter();
const query = getUrlParam();
const device = reactive({
  customer: {
    name:'',
    address: '',
    city:'',
    county: '',
    province: '',
    storey: '',
    ares:[],
    cellphone:'',
    customerDepartmentId:null,
  },
  cellphoneErr:false,
  dz:"",
  customerDepartment: {},
});

const getDeviceLsit = () => {
  queryDevice(query.id).then((res)=>{
    device.customer = {
      customerDepartmentId:res.data.customerDepartmentId
    };
    getCustomerDepartment(res.data.customerDepartmentId) // 获取客户单位
    getCustomer(res.data.customerId) // 获取客户信息
  });
}
const getCustomerDepartment = (id) => {
  queryCustomerDepartment(id).then((res)=>{
    device.customerDepartment = res.data;
    device.customer.address = res.data.address
    device.customer.city = res.data.city
    device.customer.county = res.data.county
    device.customer.province = res.data.province
    device.dz = `${res.data.province}-${res.data.city}-${res.data.county}`
  })
}

const getCustomer = (id) => {
  queryCustomer(id).then((res)=>{
    // device.customer.name = res.data.name
    device.customer.address = res.data.address
    device.customer.city = res.data.city
    device.customer.county = res.data.county
    device.customer.province = res.data.province

    device.customer.ares =[res.data.province, res.data.city, res.data.county]
  })
}

onMounted(() => {
  getDeviceLsit()
  dd.hideTitleBar()
});

const onSubmit = () => {
  let data = {
    address:device.customer.storey,
    ares:device.customer.ares,
    cellphone:device.customer.cellphone,
    departmentId:device.customer.customerDepartmentId,
    city:device.customer.city,
    county:device.customer.county,
    province:device.customer.province,
    name:device.customer.name,
  }
  CreateCustomer(data).then(res=>{
    if(res.status === 20000){
      Notify({ type: 'success', message: '创建成功' });
      router.back()
    }
  }).catch(error => {
    if(error.data){
      const jsonData = JSON.parse(error.data); // 转换为 JSON 字符串
      Notify({ type: 'warning', message: '创建失败:该号码已经存在' });
      device.cellphoneErr = true
      device.customer.cellphone = ''
    }else {
      Notify({ type: 'warning', message: '创建失败！' });
    }
//18707962579
  });
};
</script>

<template>
<div>
  <van-nav-bar fixed border left-arrow title="新增信息" left-text="返回" @click-left="router.back"/>
  <div class="page-container">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field label="客户单位" readonly v-model="device.customerDepartment.name"/>
        <van-field label="所在区县" readonly v-model="device.dz"/>
        <van-field label="责任人" required v-model.trim="device.customer.name" :rules="[{ required: true, message: '请输入责任人' }]" placeholder="请输入责任人" />
        <van-field label="联系电话" required type="number" v-model.trim="device.customer.cellphone" :rules="[{ required: true, message: '请输入联系电话' }]" placeholder="请输入联系电话" />
        <van-field label="责任人所在地" required v-model.trim="device.customer.storey" :rules="[{ required: true, message: '请输入责任人所在楼号楼层及门牌号' }]" placeholder="如:市政中心北楼9楼917室" />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
    <van-notice-bar v-if="device.cellphoneErr" mode="closeable">此联系电话已存在 请联系：4000-090-888 进行处理</van-notice-bar>
  </div>
</div>
</template>

<style scoped>

</style>